import { enMessages } from './messages';

export const LOCALE_CODES = [
   'en-us',
   'en-gb',
   'en-ca',
   'en-au',
   'en-eu',
   'fr-fr',
   'de-de',
] as const;

export type EnMessages = typeof enMessages;

export type LocaleCode = (typeof LOCALE_CODES)[number];
